import React from 'react'
import Link from 'next/link'
import AuthService from '../../../services/auth.service'
import { throttle } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import toastService from '../../../services/toast.service'

interface LoginFormProps {
	onFormSubmit: Function
	error: any
}

interface LoginFormState {
	email: string
	password: string
	hasRequestedEmailVerification: boolean
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
	private emailInput: React.RefObject<HTMLInputElement>
	private passwordInput: React.RefObject<HTMLInputElement>

	constructor(props: LoginFormProps) {
		super(props)

		this.emailInput = React.createRef()
		this.passwordInput = React.createRef()

		this.state = {
			email: '',
			password: '',
			hasRequestedEmailVerification: false,
		}

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleInputChange(event: React.FormEvent<HTMLInputElement>) {
		const target = event.target as HTMLInputElement
		const value = target.value
		const name = target.name

		this.setState({
			[name]: value,
		} as Pick<LoginFormState, 'email' | 'password'>)
	}

	handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		this.setState(
			{
				email: this.emailInput.current.value,
				password: this.passwordInput.current.value,
			},
			() => {
				this.props.onFormSubmit(this.state)
			}
		)
	}

	requestEmailVerification = throttle(
		() => {
			const userEmail: string = this.state.email

			if (!userEmail)
				return toastService.showError(
					'Please fill your e-mail and password in the login screen.'
				)

			this.setState(
				{
					hasRequestedEmailVerification: true,
				},
				() => {
					return AuthService.requestEmailVerification({
						email: userEmail,
					})
						.then(() => {
							toastService.showSuccess('Request submitted')
						})
						.catch(() => {
							toastService.showError(
								'Something went wrong while requesting a verification e-mail'
							)
						})
				}
			)
		},
		5000,
		{
			trailing: false,
		}
	)

	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<div className="mb-4">
					<label
						className="block font-sans text-purple-900 text-sm font-bold mb-2"
						htmlFor="email"
					>
						Email
					</label>
					<input
						className="w-full"
						ref={this.emailInput}
						autoComplete="email"
						name="email"
						value={this.state.email}
						onChange={this.handleInputChange}
						id="email"
						type="text"
						placeholder="Email"
						required
					/>
				</div>
				<div className="mb-4">
					<label
						className="block font-sans text-purple-900 text-sm font-bold mb-2"
						htmlFor="password"
					>
						Password
					</label>
					<input
						className="w-full"
						ref={this.passwordInput}
						name="password"
						autoComplete="current-password"
						value={this.state.password}
						onChange={this.handleInputChange}
						id="password"
						type="password"
						placeholder="Password"
						required
					/>
				</div>
				{this.props.error === 'unauthorized' && (
					<p className="text-red-600">Username or password is incorrect</p>
				)}
				{this.props.error === 'Invalid credentials' && (
					<p className="text-red-600">Username or password is incorrect</p>
				)}
				{this.props.error === 'Email address not verified' && (
					<div className="my-8 space-y-3 border py-6 rounded-sm p-4 text-black bg-yellow-300 flex flex-col text-center items-center">
						<FontAwesomeIcon className="mx-4 " icon={faExclamationTriangle} />
						<p className="font-semibold ">Your e-mail is not verified.</p>
						<p className="text-sm px-4 my-4">
							Please verify your email address before you can login. Check your
							inbox for a verification link.
						</p>
						<button
							disabled={this.state.hasRequestedEmailVerification}
							type="button"
							onClick={() => {
								this.requestEmailVerification()
							}}
							className="bg-gray-100 text-black"
						>
							{' '}
							<FontAwesomeIcon icon={faArrowRight} className="mr-1" />{' '}
							<span>Send verification e-mail</span>
						</button>
					</div>
				)}

				<Link href="/request-password-reset">
					<a className="inline-block align-baseline text-sm text-blue-500 hover:text-blue-600">
						Recover password
					</a>
				</Link>

				<div className="flex w-full items-center mt-4">
					<button
						className="bg-gray-900 flex-grow text-white duration-300 transition-colors hover:bg-black"
						type="submit"
					>
						Sign In
					</button>
				</div>
			</form>
		)
	}
}

export default LoginForm

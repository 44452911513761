import React from 'react'

const ConextLogo = require('../../../assets/svg/SURFconext-logo.svg') as string

const SurfConextAuthButton = ({ text, authUrl }) => {
	return (
		<a
			href={authUrl}
			style={{ whiteSpace: 'normal' }}
			className="break-normal px-3 py-2 bg-white hover:bg-gray-50 border my-3 border-gray-400 rounded-lg sm:rounded-full text-center w-full flex flex-wrap justify-center items-center shadow-sm"
		>
			<span className="text-xs sm:text-sm text-gray-800 block px-3 my-2">
				{text}
			</span>
			<span className="block w-full" style={{ maxWidth: '5rem' }}>
				<ConextLogo />
			</span>
		</a>
	)
}

export default SurfConextAuthButton
